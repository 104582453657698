import { ref, computed } from 'vue';
import { defineStore } from 'pinia';
import useBatchStore from './batch';

const useGroupMeetingStore = defineStore('groupMeeting', () => {
  const groupMeeting = ref(null);
  const groupMeetings = ref([]);
  const companies = ref([]);

  const batchGroup = computed(
    () => useBatchStore().batchGroups.find(
      (group) => group.id === groupMeeting.value?.batchGroupId,
    ),
  );

  function setGroupMeeting(value) {
    groupMeeting.value = value;
  }

  function setGroupMeetings(value) {
    groupMeetings.value = value;
  }

  function setCompanies(value) {
    companies.value = value.sort((a, b) => {
      if (a.weeklyUpdates.length === 0) return 1;
      if (b.weeklyUpdates.length === 0) return -1;

      return a.weeklyUpdates[a.weeklyUpdates.length - 1].id -
             b.weeklyUpdates[b.weeklyUpdates.length - 1].id;
    });
  }

  return {
    groupMeeting,
    batchGroup,
    groupMeetings,
    companies,
    setGroupMeeting,
    setGroupMeetings,
    setCompanies,
  };
});

export { useGroupMeetingStore as default };
